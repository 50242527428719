function validateHierarchicalOrder(existingOrders: string[], newOrder: string) {
    const maxLevels = 4;
    // Validate format: 1 to 999 up to 4 levels, disallowing leading zeroes or zero as a part
    const orderPattern = `^([1-9]\\d{0,2})(\\.([1-9]\\d{0,2})){0,${maxLevels - 1}}$`;
    if (!new RegExp(orderPattern).test(newOrder)) {
        return {
            isValid: false,
            errorMessage: "Invalid order format. Use positive integers (1 to 999) in hierarchical order, e.g., 1, 1.1, 2.3.4.5. '0' is not allowed."
        };
    }
    
    const newOrderParts = newOrder.split('.');
    // Check if parent order exists
    if (newOrderParts.length > 1) {
        const parentOrder = newOrderParts.slice(0, -1).join('.');
        if (!existingOrders.includes(parentOrder)) {
            return { isValid: false, errorMessage: `Parent order ${parentOrder} does not exist.` };
        }
    }
    // Ensure order uniqueness
    if (existingOrders.includes(newOrder)) {
        return { isValid: false, errorMessage: `Order ${newOrder} already exists.` };
    }
    // Check sibling sequence
    if (newOrderParts.length > 1) {
        const parentOrder = newOrderParts.slice(0, -1).join('.');
        const siblingNumber = parseInt(newOrderParts[newOrderParts.length - 1], 10);
        for (let i = 1; i < siblingNumber; i++) {
            const siblingOrder = `${parentOrder}.${i}`;
            if (!existingOrders.includes(siblingOrder)) {
                return {
                    isValid: false,
                    errorMessage: `Sibling order ${siblingOrder} does not exist. Cannot create ${newOrder}.`,
                };
            }
        }
    } else {
        // For root-level orders, check sequence
        const orderNumber = parseInt(newOrderParts[0], 10);
        if(orderNumber > 1 && !existingOrders.includes(`${orderNumber-1}`)){
            return {
                isValid: false,
                errorMessage: `Order ${orderNumber-1} does not exist. Cannot create ${newOrder}.`,
            };
        }
    }
    return { isValid: true, errorMessage: "Order is valid." };
}

const validateOrder = (
    value: string,
    currentKey: string,
    dataObj: any
): {
    isValid: boolean;
    errorMessage?: string
} => {
    const { questionColumnMapping } = dataObj;
    // Default configuration
    const config = {
        maxDepth: 4,
        maxLength: 10,
        allowedChars: /^[0-9.]+$/
    };

    const mergedOptions = { ...config };

    // Trim the value
    const trimmedValue = value.trim();

    // Check if empty (optional)
    if (!trimmedValue) {
        return { isValid: true };
    }

    // Check total length
    if (trimmedValue.length > mergedOptions.maxLength) {
        return {
            isValid: false,
            errorMessage: `Maximum length is ${mergedOptions.maxLength} characters`
        };
    }

    // Check allowed characters
    if (!mergedOptions.allowedChars.test(trimmedValue)) {
        return {
            isValid: false,
            errorMessage: 'Only numbers and dots are allowed'
        };
    }

    // Split into segments
    const segments = trimmedValue.split('.');

    // Check depth
    if (segments.length > mergedOptions.maxDepth) {
        return {
            isValid: false,
            errorMessage: `Maximum hierarchy depth is ${mergedOptions.maxDepth - 1}`
        };
    }

    // Validate each segment
    for (const segment of segments) {
        // Ensure no leading zeros (except single 0)
        if (segment.length > 1 && segment.startsWith('0')) {
            return {
                isValid: false,
                errorMessage: 'No leading zeros allowed in segments'
            };
        }

        // Ensure segment is a valid number
        if (!/^\d+$/.test(segment)) {
            return {
                isValid: false,
                errorMessage: 'Segments must be numeric'
            };
        }

        // Optional: Limit segment value (e.g., max 999 in each segment)
        if (parseInt(segment, 10) > 999) {
            return {
                isValid: false,
                errorMessage: 'Segment values cannot exceed 999'
            };
        }
    }

    // Hierarchical order validation
    const existingOrders = dataObj.dataList
        .filter((item: any) => item.key !== currentKey)
        .map((item: any) => item[questionColumnMapping['order']])
        .filter((order: any) => order !== undefined && order !== null);

   return validateHierarchicalOrder(existingOrders, value)
};

const validateQuestionOrder = (item: any, globalIndex: number, dataObj: any) => {
    const {
        currentPage,
        currentPageSize,
        validationGlobalErrors,
        errorRefs,
        setValidationGlobalErrors,
        questionColumnMapping
    } = dataObj;
    const index = globalIndex - ((currentPage - 1) * currentPageSize);
    const page = Math.floor(globalIndex / currentPageSize) + 1;
    const row = (globalIndex % currentPageSize) + 1;

    const isOrderArray = Array.isArray(item[questionColumnMapping['order']])
    
    if (!isOrderArray && !item[questionColumnMapping['order']]?.trim()) {
        if (errorRefs?.current[`order_${index}`]) {
            errorRefs.current[`order_${index}`].style.border = '1px solid red';
            errorRefs.current[`order_${index}`].id = `order_${globalIndex}`;
        }
        setValidationGlobalErrors(`order_${globalIndex}`, 'Add', { page: page, row: row, message: 'Order is mandatory' });
    }
    if (!isOrderArray && (item[questionColumnMapping['order']]?.trim().length > 0)) {
        const inputValue = item[questionColumnMapping['order']];
        const validationObj = validateOrder(inputValue, item['key'], dataObj);

        if (!validationObj?.isValid) {
            if (errorRefs?.current[`order_${index}`]) {
                errorRefs.current[`order_${index}`].style.border = '1px solid red';
                errorRefs.current[`order_${index}`].id = `order_${globalIndex}`;
            }
            setValidationGlobalErrors(`order_${globalIndex}`, 'Add', { page: page, row: row, message: `${validationObj?.errorMessage}` });
        } else {
            if (errorRefs?.current[`order_${index}`]) {
                errorRefs.current[`order_${index}`].style.border = '1px solid white';
            }
            validationGlobalErrors.hasOwnProperty(`order_${globalIndex}`) && setValidationGlobalErrors(`order_${globalIndex}`, 'Delete', '');
        }
    }
}

const validateQuestionVariable = (item: any, globalIndex: number, dataObj: any) => {
    const {
        dataList,
        currentPage,
        currentPageSize,
        validationGlobalErrors,
        errorRefs,
        setValidationGlobalErrors,
        questionColumnMapping,
    } = dataObj;
    const index = globalIndex - ((currentPage - 1) * currentPageSize);
    const page = Math.floor(globalIndex / currentPageSize) + 1;
    const row = (globalIndex % currentPageSize) + 1;
    const variableName = item[questionColumnMapping['variable_name']];
    const isVariableArray = Array.isArray(variableName)

    if (!isVariableArray && !variableName?.trim()) {
        if (errorRefs?.current[`variable_name_${index}`]) {
            errorRefs.current[`variable_name_${index}`].style.border = '1px solid red';
            errorRefs.current[`variable_name_${index}`].id = `variable_name_${globalIndex}`;
        }
        setValidationGlobalErrors(`variable_name_${globalIndex}`, 'Add', { page: page, row: row, message: 'Variable name is mandatory' });
    }

    if (!isVariableArray && variableName?.trim().length > 0) {
      const startsWithAlphanumeric = /^[A-Za-z0-9]/;   
      const variableNamePattern = /^[A-Za-z0-9-_]+$/;
      const minimumThreeChar = /^[A-Za-z0-9-_]{3,}$/;

      const isVariableNameStartsWithAlphanumeric = startsWithAlphanumeric.test(variableName);
      const isVariableNameValid = variableNamePattern.test(variableName);
      const isMinimumThreeChar = minimumThreeChar.test(variableName);
      // Check for duplicates
      const isDuplicate = dataList.some((item: any) => item[questionColumnMapping['variable_name']] === variableName && item.key !== dataList[globalIndex].key);

      // Update validation logic to include duplicate check and pattern match
      if (!isVariableNameStartsWithAlphanumeric || !isVariableNameValid || !isMinimumThreeChar || isDuplicate) {
        if (errorRefs?.current[`variable_name_${index}`]) {
          errorRefs.current[`variable_name_${index}`].style.border = '1px solid red';
          errorRefs.current[`variable_name_${index}`].id = `variable_name_${globalIndex}`;
        }
        let errorMessage = '';
        if (!isVariableNameStartsWithAlphanumeric) {
            errorMessage = 'Variable should start with alphanumeric characters';
        }
        else if (!isVariableNameValid) {            
          errorMessage = 'Only alphanumeric characters, underscore and hyphen are allowed';
        } 
        else if (!isMinimumThreeChar) {            
            errorMessage = 'Variable should be minimum three characters';
        } 
        else if (isDuplicate) {
          errorMessage = 'This variable name already exists';
        }
        setValidationGlobalErrors(`variable_name_${globalIndex}`, 'Add', { page: page, row: row, message: errorMessage });
      } else {
        if (errorRefs?.current[`variable_name_${index}`]) {
          errorRefs.current[`variable_name_${index}`].style.border = '1px solid white';
        }
        validationGlobalErrors.hasOwnProperty(`variable_name_${globalIndex}`) && setValidationGlobalErrors(`variable_name_${globalIndex}`, 'Delete', '');
      }
    }
}

const validateQuestionName = (item: any, globalIndex: number, dataObj: any) => {
    const {
        currentPage,
        currentPageSize,
        validationGlobalErrors,
        errorRefs,
        setValidationGlobalErrors,
        questionColumnMapping,
    } = dataObj;
    const index = globalIndex - ((currentPage - 1) * currentPageSize);
    const page = Math.floor(globalIndex / currentPageSize) + 1;
    const row = (globalIndex % currentPageSize) + 1;
    const questionName = item[questionColumnMapping['question']];
    const isQuestionNameArray = Array.isArray(questionName)

    if (!isQuestionNameArray && !questionName?.trim().length) {
        if (errorRefs?.current[`question_${index}`]) {
            errorRefs.current[`question_${index}`].style.border = '1px solid red';
            errorRefs.current[`question_${index}`].id = `question_${globalIndex}`;
        }
        setValidationGlobalErrors(`question_${globalIndex}`, 'Add', { page: page, row: row, message: 'Question description is mandatory' });
    } else {
        if (errorRefs?.current[`question_${index}`]) {
          errorRefs.current[`question_${index}`].style.border = '1px solid white';
        }
        validationGlobalErrors.hasOwnProperty(`question_${globalIndex}`) && setValidationGlobalErrors(`question_${globalIndex}`, 'Delete', '');
      }
}

const validateQuestionType = (item: any, globalIndex: number, dataObj: any) => {
    const {
        currentPage,
        currentPageSize,
        validationGlobalErrors,
        errorRefs,
        setValidationGlobalErrors,
        questionColumnMapping,
    } = dataObj;
    const index = globalIndex - ((currentPage - 1) * currentPageSize)
    const page = Math.floor(globalIndex / currentPageSize) + 1;
    const row = (globalIndex % currentPageSize) + 1;
    const questionType = item[questionColumnMapping['question_type']];
    const isQuestionTypeArray = Array.isArray(questionType)

    if (!isQuestionTypeArray && !questionType?.trim()) {
        if (errorRefs?.current[`question_type_${index}`]) {
            errorRefs.current[`question_type_${index}`].style.border = '1px solid red';
            errorRefs.current[`question_type_${index}`].id = `question_type_${globalIndex}`;
        }
        setValidationGlobalErrors(`question_type_${globalIndex}`, 'Add', { page: page, row: row, message: 'Question Type is mandatory' });
    } else {
        if (errorRefs?.current[`question_type_${index}`]) {
          errorRefs.current[`question_type_${index}`].style.border = '1px solid white';
        }
        validationGlobalErrors.hasOwnProperty(`question_type_${globalIndex}`) && setValidationGlobalErrors(`question_type_${globalIndex}`, 'Delete', '');
      }
}

const validatePossibleOptions = (item: any, globalIndex: number, dataObj: any) => {
    const {
        currentPage,
        currentPageSize,
        validationGlobalErrors,
        errorRefs,
        setValidationGlobalErrors,
        questionColumnMapping,
    } = dataObj;
    const index = globalIndex - ((currentPage - 1) * currentPageSize)
    const page = Math.floor(globalIndex / currentPageSize) + 1;
    const row = (globalIndex % currentPageSize) + 1;

    if (!['TEXT', 'DATE', 'NUMBER', 'BIN', 'CURRENCY'].includes(item[questionColumnMapping['question_type']]) && ((item[questionColumnMapping['possible_options']]?.length < 2) || !Array.isArray(item[questionColumnMapping['possible_options']]))) {        
        if (errorRefs?.current[`possible_options_${index}`]) {
          errorRefs.current[`possible_options_${index}`].style.border = '1px solid red';
          errorRefs.current[`possible_options_${index}`].id = `possible_options_${globalIndex}`;
        }
        setValidationGlobalErrors(`possible_options_${globalIndex}`, 'Add', { page: page, row: row, message: 'Atleast two options are mandatory' });
      } else {
        if (errorRefs?.current[`possible_options_${index}`]) {
          errorRefs.current[`possible_options_${index}`].style.border = '1px solid white';
        }
        validationGlobalErrors.hasOwnProperty(`possible_options_${globalIndex}`) && setValidationGlobalErrors(`possible_options_${globalIndex}`, 'Delete', '');
      }
}

export const validateQuestions = (dataObj: any) => {
    const { dataList, resetValidationGlobalErrors } = dataObj;
    resetValidationGlobalErrors({});
    dataList.forEach((item: any, globalIndex: number) => {
        validateQuestionOrder(item, globalIndex, dataObj);
        validateQuestionVariable(item, globalIndex, dataObj);
        validateQuestionName(item, globalIndex, dataObj);
        validateQuestionType(item, globalIndex, dataObj);
        validatePossibleOptions(item, globalIndex, dataObj);

    });

}
