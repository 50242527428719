import { message } from "antd";
import { ProjectImporterToolService } from "modules/organization/services/projectImporterTool.service";

function getKeyByValue(value: string, columnMapping: any) {
  for (const [key, val] of Object.entries(columnMapping)) {
    if (val === value) {
      return key;
    }
  }
  return null;
}

function findKey(jsonObj: any, key: string) {
  if (jsonObj.hasOwnProperty(key)) {
    return jsonObj[key];
  }
  return null;
}

const onSaveQuestions = async (questionOriginalDataList: any, dataList: any, questionColumnMapping: any, excelHeaderList: any, getJsonDifferences: Function, userDetails: any, validationGlobalErrors: any, project_slug: string | undefined, updateStep: Function, isManual: boolean = false, setQuestionOriginalDataList?: Function ) => {
  try {
    const updatedOriginalDataList = questionOriginalDataList.map((item: any) => {
      const { original_possible_options, ...rest } = item;
      return rest;
    });
    
    const updatedDataList = dataList.map((item: any) => {
      const { original_possible_options, ...rest } = item;
      let extracted = rest;
      if(extracted[questionColumnMapping.question_type] === 'BIN'){
        extracted[questionColumnMapping.possible_options] = ['Yes', 'No'];
      } else if(['TEXT', 'CURRENCY', 'DATE', 'NUMBER'].includes(extracted[questionColumnMapping.question_type])) {
        extracted[questionColumnMapping.possible_options] = null;
      }
      return extracted;
    });

    const finalQuestionData = {
      header: excelHeaderList,
      data: updatedDataList
    }

    const differences = getJsonDifferences(updatedOriginalDataList, updatedDataList);

    const payload = {
      user_id: userDetails?.id,
      org_id: userDetails?.org_id,
      question_data: finalQuestionData,
      column_mapping: questionColumnMapping,
      question_diff: differences,
      only_save: true,
      have_error: (Object.keys(validationGlobalErrors).length > 0)
    }  
    const result = await new ProjectImporterToolService().updateImporterToolQuestion(project_slug ?? "", payload);
    setQuestionOriginalDataList && setQuestionOriginalDataList(updatedDataList);
    updateStep(result?.data.data.questionData);
    isManual && message.success({
      key: "updateQuestionData",
      content: "Question data updated successfully!",
      duration: 2,
    });

  } catch (error) {
    console.error(error);
    message.error({
      key: "updateQuestionData",
      content: "Something went wrong!",
      duration: 2,
    });
  }
}

const onSaveJurisdictions = async (dataList: any, columnMapping: any, excelHeaderList: any, validationGlobalErrors: any, project_slug: string | undefined, isManual: boolean = false ) => {
  try {
    const finalJurisdictionData = {
      header: excelHeaderList,
      data: dataList
    }
    const payload = {
      jurisdiction_data: finalJurisdictionData,
      column_mapping: columnMapping,
      only_save: true,
      have_error: (Object.keys(validationGlobalErrors).length > 0)
    }
    await new ProjectImporterToolService().updateImporterToolJurisdiction(project_slug ?? "", payload);
    isManual && message.success({
      key: "updateJurisdictionData",
      content: "Jurisdiction data updated successfully!",
      duration: 2,
    });
  } catch (error) {
    console.error(error);
    message.error({
      key: "updateJurisdictionData",
      content: "Something went wrong!",
      duration: 2,
    });
  }
}

export {
    getKeyByValue,
    findKey,
    onSaveQuestions,
    onSaveJurisdictions,
}