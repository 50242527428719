import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Drawer, Form, Input, message, Skeleton, Space, Spin, Switch, Tooltip } from 'antd';
import { useCreateNewProjectStore } from '../../store/createNewProjectStore';
import { OrganizationService, ProjectListService } from '../../services';
import CustomSelectInput from '../../../shared/components/CustomSelectInput';
import { findMissingPermissions, uniqueFeedBack } from '../../../shared/utility';
import { IProjectFormData } from '../../models/interface';
import { RBAC, useRbac } from '../../../../auth/rbac/rbac';
import { useOrganizationRoutes } from 'routes/organizationRoute';
import { useNavigate } from 'react-router-dom';
import { MenuKeys } from 'modules/organization/models/enums';
import CustomManageUserList from 'modules/shared/components/CustomManageUserList';
import { useUserStore } from 'modules/shared/store';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { useProjectDetailsStore } from 'modules/organization/store';
import { usePromptText } from 'modules/shared/components/accessibility/PromptStore';

const ProjectInfoSection: React.FC<{ validateUniqueSlug: Function }> = ({
  validateUniqueSlug
}) => {
  const form = Form.useFormInstance();
  const [isSlugEdited, setIsSlugEdited] = useState<boolean>(false);
  const {
    slugCheckOnSubmit,
    slugExists,
    setSlugCheckOnSubmit,
    setSlugExists,
    tagList,
    projectDetails
  } = useCreateNewProjectStore();

  const handleSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSlugEdited(true);
    slugExists && setSlugExists(null);
    slugCheckOnSubmit && setSlugCheckOnSubmit(null);
  };

  const onChangeTitleHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isSlugEdited && !projectDetails?.project_id) {
      const formattedValue = event.target.value
        .toLowerCase()
        .replaceAll(' ', '-')
        .replaceAll(/[^\w\s-]/gi, '');
      form.setFieldsValue({ slug: formattedValue });
    }
  };

  const validationOfSlug = (_: any, value: string) => {
    const pattern1 = /^[a-z0-9].+/;
    const pattern2 = /^(?![_-])[a-z0-9_-]+$/;

    if (value.length > 0 && value.length < 3) {
      return Promise.reject(new Error('It should be minimum three characters.'));
    } else if (pattern1.test(value) === false) {
      return Promise.reject(
        new Error('It should start with a lowercase letter or number.')
      );
    } else if (pattern2.test(value) === false) {
      return Promise.reject(
        new Error('It only accept lowercase letters, numbers, hyphens, and underscores.')
      );
    }
    return Promise.resolve();
  };

  return (
    <React.Fragment>
      <Form.Item
        name="title"
        label="Project Title"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Project title is required'
          }
        ]}
      >
        <Input
          aria-label="project-title"
          aria-labelledby="label-project-title"
          placeholder="Enter project title"
          onChange={onChangeTitleHandler}
          maxLength={200}
          autoFocus
        />
      </Form.Item>
      {!projectDetails?.project_id && (
        <Form.Item
          name="slug"
          label="Project Slug"
          rules={[
            { required: true, message: 'Project slug is required' },
            { validator: validationOfSlug }
          ]}
        >
          <Input
            placeholder="Enter project slug"
            onChange={handleSlugChange}
            suffix={uniqueFeedBack(slugExists, slugCheckOnSubmit, {
              success: 'Slug is available',
              error: 'Slug already taken'
            })}
            maxLength={200}
            onBlur={(e) => {
              validateUniqueSlug(e.target.value).catch(console.error);
            }}
          />
        </Form.Item>
      )}
      <CustomSelectInput
        options={tagList}
        name="tags"
        label="Project Tags"
        placeholder={'Add tags'}
        mode="tags"
        showArrow={false}
      />
      <Form.Item name="description" label="Description">
        <Input.TextArea rows={4} maxLength={1000} showCount />
      </Form.Item>
      { !projectDetails?.project_id && <RBAC allowedPermissions={[ERbacPermissions.ORG_IMPORTING_TOOL_MANAGE]}>        
        <Form.Item label="Allow Importing" name="is_importer">
          <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(value) => {
            if(value) form.setFieldsValue({ tags: form.getFieldValue("tags").includes("importer") ? form.getFieldValue("tags") : [...(form.getFieldValue("tags")), "importer"] });
            else form.setFieldsValue({ tags: form.getFieldValue("tags").filter((each: any) => each != "importer") })
          }}/>
        </Form.Item>
      </RBAC>}
    </React.Fragment>
  );
};

const ProjectUsersSection: React.FC<{}> = () => {
  const { groupList, userList, projectDetails } = useCreateNewProjectStore();

  return (
    <React.Fragment>
      <CustomSelectInput
        options={groupList}
        name="groups_associated"
        label="Add Permission by Group"
        placeholder={'Select groups'}
      />
      <CustomManageUserList
        userList={userList}
        usersAssociated={projectDetails?.users_associated??[]}
        show_direct_permission={true}
        currentUserRoleId={projectDetails?.project_role_id}
      />
    </React.Fragment>
  );
};

const CreateNewProjectForm: React.FC<{ visibility: boolean }> = ({
  visibility
}) => {
  const [form] = Form.useForm<IProjectFormData>();
  const { userDetails, hasPermissions } = useRbac();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {setPromptType,setPromptText} = usePromptText();

  const {
    setDrawerState,
    drawerState,
    setSlugCheckOnSubmit,
    setSlugExists,
    drawerOptions,
    projectDetails,
    projectRoleData,
    getDrawerDetails,
    setFormLoader,
    setProjectCreateEditStatus,
    gearAction,
    setGearAction
  } = useCreateNewProjectStore();

  const { clearProjectDetails, getProjectDetails } = useProjectDetailsStore();

  const { appConfigurationList } = useUserStore();

  const projectFormData: IProjectFormData = {
    title: undefined,
    slug: undefined,
    tags: [],
    description: null,
    groups_associated: [],
    users_associated: [
      { user_id: undefined, permissions_id: undefined, role_id: undefined },
      { user_id: undefined, permissions_id: undefined, role_id: undefined }
    ],
    permissions_id_removed: [
      { user_id: undefined, permissions_id: undefined, role_id: undefined },
      { user_id: undefined, permissions_id: undefined, role_id: undefined }
    ]
  };

  const validateUniqueSlug = async (value: string, checkOnSubmit?: boolean) => {
    if (!value.trim() || form.getFieldError('slug').length) {
      return;
    }

    if (checkOnSubmit) {
      setSlugCheckOnSubmit('checking');
    } else {
      setSlugExists('checking');
    }

    try {
      const response = await new ProjectListService().checkProjectUniqueSlug({
        project_slug: value
      });
      if (response.data.data.exists) {
        checkOnSubmit ? setSlugCheckOnSubmit('exist') : setSlugExists('exist');
      } else {
        checkOnSubmit
          ? setSlugCheckOnSubmit('unique')
          : setSlugExists('unique');
      }
      return response.data.data.exists;
    } catch (error) {
      console.error(error);
      let promptText = 'Something went wrong please try again!';
      setPromptType("assertive");
      setPromptText(promptText);
      message.error({
        content: promptText
      });
      setDrawerState(false);
    }
  };

  const formatFormData = (values: IProjectFormData) => {
    let tmpUsersAssociated = values.users_associated?.filter(
      ({ user_id }) => user_id
    );
    if (!drawerOptions.key || projectDetails?.is_user_associated) {
      tmpUsersAssociated = tmpUsersAssociated
        ? [
            ...tmpUsersAssociated,
            { user_id: userDetails?.sub, permissions_id: undefined, role_id: userDetails?.org_id === appConfigurationList?.individual_org_id ? 5 : 1 }
          ]
        : [{ user_id: userDetails?.sub, permissions_id: undefined, role_id: userDetails?.org_id === appConfigurationList?.individual_org_id ? 5 : 1 }];
    }
    values.users_associated = tmpUsersAssociated ?? [];
    values.title = values.title ? values.title : projectDetails?.title;
    values.slug = values.slug ? values.slug : projectDetails?.slug;
    values.description = values.description
      ? values.description
      : projectDetails?.description ?? null;
    values.tags = values.tags ? values.tags : projectDetails?.tags ?? [];

    if ([MenuKeys.EDIT_PROJECT, MenuKeys.MANAGE_PROJECT_USERS].includes(drawerOptions.key)) {
      const groups_removed = (projectDetails?.groups_associated ?? []).filter(eachGroup => !values.groups_associated.includes(eachGroup));
      values.groups_removed = groups_removed ?? [];
      values.permissions_id_removed = findMissingPermissions(projectDetails?.users_associated, values.users_associated)
    }
    return values;
  };

  const onFinish = async (values: IProjectFormData) => {
    const createProjectKey = 'createProject';
    let promptText = drawerOptions.toastMessage?.loading;
    setIsSubmitting(true);
    setPromptType("assertive");
    setPromptText(promptText as string);
    message.open({
      key: createProjectKey,
      content: promptText,
      type: 'loading',
      duration: 0
    });
    const checkStatus =
      !projectDetails?.project_id &&
      (await validateUniqueSlug(values?.slug ?? '', true).catch(console.error));

    if (checkStatus && !projectDetails?.project_id) {
      message.destroy(createProjectKey);
      setIsSubmitting(false);
      form.scrollToField('slug');
      return;
    }
    
    if (checkStatus === false) {
      values = formatFormData(values);
      createEditProject(values, createProjectKey);
    }
  };

  const editManageProject = (values: IProjectFormData) => {
    if (drawerOptions.key === MenuKeys.EDIT_PROJECT) {
      return new ProjectListService().editProject(values);
    }
    return new ProjectListService().manageProjectPermissions(values);
  };

  const createEditProject = async (
    values: IProjectFormData,
    createProjectKey: any
  ) => {
    try {
      const { data } = projectDetails?.project_id
        ? await editManageProject(values)
        : await new ProjectListService().createProject(values);

      if (data.statusCode === 200) {
        setProjectCreateEditStatus(data.data.projectDetails);
        setPromptType("assertive");
        setPromptText(drawerOptions.toastMessage?.success as string);
        message.success({
          key: createProjectKey,
          content: drawerOptions.toastMessage?.success,
          duration: 2
        });
        if(gearAction === true){
          setGearAction(false)
          clearProjectDetails();
          getProjectDetails(projectDetails?.slug ?? '').catch(error => console.log(error));
        }
      } else {
        setIsSubmitting(false);
        setPromptType("assertive");
        setPromptText(drawerOptions.toastMessage?.success as string);
        message.error({
          key: createProjectKey,
          content:drawerOptions.toastMessage?.error
        });
        setDrawerState(false);
      }
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setPromptType("assertive");
      setPromptText(drawerOptions.toastMessage?.error as string);
      message.error({
        key: createProjectKey,
        content: drawerOptions.toastMessage?.error
      });
      setDrawerState(false);
    }
  };

  useEffect(() => {
    if (!visibility) {
      form.resetFields();
    }
  }, [visibility, form]);

  useEffect(() => {
    if (drawerState && drawerOptions?.data?.slug && !projectDetails) {
      getDrawerDetails(projectRoleData, drawerOptions?.data?.slug, hasPermissions([ERbacPermissions.ORG_PROJECT_SHOW_MANAGE_GROUP_MEMBER])).catch(
        console.error
      );
    }
  }, [
    drawerState,
    drawerOptions,
    getDrawerDetails,
    projectRoleData,
    projectDetails
  ]);

  useEffect(() => {
    if (projectDetails && drawerState) {
      const {
        title,
        slug,
        tags,
        description,
        groups_associated,
        users_associated
      } = projectDetails;
      form.setFieldsValue({
        title,
        slug,
        tags: tags ?? [],
        description,
        groups_associated: groups_associated ?? [],
        users_associated: users_associated ?? []
      });
      setFormLoader(false);
    }
  }, [drawerState, projectDetails, form, setFormLoader]);

  return (
    <Form
      form={form}
      disabled={isSubmitting}
      layout="vertical"
      onFinish={(props) => {
        onFinish(props).catch(console.error);
      }}
      autoComplete="off"
      className="drawerContent"
      initialValues={projectFormData}
      name="form"
    >
      <div className="blkContent">
        {[0, 1].includes(drawerOptions.key) && (
          <ProjectInfoSection validateUniqueSlug={validateUniqueSlug} />
        )}
        <RBAC 
           allowedPermissions={[
            ERbacPermissions.ORG_PROJECT_SHOW_MANAGE_GROUP_MEMBER,
          ]}
        >
          <ProjectUsersSection />
        </RBAC>
      </div>
      <div className="blkFooter">
        <Form.Item>
          <Space>
            <Button htmlType="button" onClick={() => setDrawerState(false)}>
              Cancel
            </Button>
            <Form.Item shouldUpdate>
              {({ isFieldsTouched, isFieldValidating, getFieldsError }) => {
                const isProjectTitle = isFieldsTouched(['title']);
                const isProjectSlug = isFieldsTouched(['slug']);
                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={Boolean(
                      !projectDetails?.project_id &&
                        (!isProjectTitle ||
                          !isProjectSlug ||
                          isFieldValidating('slug') ||
                          getFieldsError().filter(({ errors }) => errors.length)
                            .length)
                    )}
                  >
                    {drawerOptions.submitBtnLabel}
                  </Button>
                );
              }}
            </Form.Item>
          </Space>
        </Form.Item>
      </div>
    </Form>
  );
};

const OrgCreateNewProject: React.FC<{
  createButton?: { label: string; className?: string };
  disabled?: { is_allowed: boolean; limit_message: string };
  loadingLimit?: boolean;
}> = ({ createButton, disabled, loadingLimit }) => {
  const [visibility, setVisibility] = useState<boolean>(false);
  const { hasPermissions } = useRbac();
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const navigate = useNavigate();
  const buttonEle = useRef<HTMLButtonElement>(null);

  const {
    drawerState,
    setDrawerState,
    setTagList,
    resetState,
    setUserList,
    setGroupList,
    drawerOptions,
    formLoader,
    projectCreateEditStatus,
    setDrawerOptions,
    setProjectCreateEditStatus
  } = useCreateNewProjectStore();

  const getTagList = useCallback(async () => {
    try {
      const result = await new ProjectListService().getProjectTagListData();
      const { tagList } = result.data.data;
      setTagList(tagList);
    } catch (error) {
      console.error(error);
      message.error({
        content: 'Something went wrong please try again!'
      });
      setDrawerState(false);
    }
  }, [setTagList, setDrawerState]);

  const getOrganizationUsers = useCallback(async () => {
    try {
      const result = await new OrganizationService().getOrganizationUserList();
      const { userList } = result.data.data;
      setUserList(userList);
    } catch (error) {
      console.error(error);
      message.error({
        content: 'Something went wrong please try again!'
      });
      setDrawerState(false);
    }
  }, [setUserList, setDrawerState]);

  const getOrganizationGroups = useCallback(async () => {
    try {
      const result = await new OrganizationService().getOrganizationGroupList();
      const { groupList } = result.data.data;
      setGroupList(groupList);
    } catch (error) {
      console.error(error);
      message.error({
        content: 'Something went wrong please try again!'
      });
      setDrawerState(false);
    }
  }, [setGroupList, setDrawerState]);

  const afterOpenChange = (visible: boolean) => {
    setVisibility(visible);
    if (!visible) {
      resetState();
    } else {
      setProjectCreateEditStatus();
    }
    if (drawerOptions.key === 0 && !visible && projectCreateEditStatus) {
      projectCreateEditStatus !== 'UNKNOWN_ERROR' &&
        navigate(
          `${organizationBaseRouteUrl}/project/${projectCreateEditStatus.slug}`
        );
    }
  };

  useEffect(() => {
    if (drawerState && !drawerOptions.data) {
      getTagList().catch(console.error);
      if (hasPermissions([ERbacPermissions.ORG_PROJECT_SHOW_MANAGE_GROUP_MEMBER])) {
        getOrganizationUsers().catch(console.error);
        getOrganizationGroups().catch(console.error);
      }
    }
  }, [
    getTagList,
    drawerState,
    getOrganizationUsers,
    getOrganizationGroups,
    drawerOptions
  ]);

  useEffect(() => {
    if (projectCreateEditStatus === 'UNKNOWN_ERROR') {
      message.error({
        content: 'Something went wrong please try again!'
      });
      setDrawerState(false);
    }
  }, [projectCreateEditStatus, setDrawerState]);

  useEffect(() => {
    projectCreateEditStatus && setDrawerState(false);
  }, [projectCreateEditStatus, setDrawerState]);

  return (
    <React.Fragment>
      {createButton && (
        !loadingLimit ?
        <Tooltip title={disabled?.limit_message} placement="left" trigger={["hover", "focus"]}>
          <Button
            role="button"
            type="primary"
            className={`${createButton.className} flexLeftGap`}
            onClick={() => {
              setDrawerOptions();
              setDrawerState(true);
              buttonEle.current?.blur();
            }}
            disabled={!disabled?.is_allowed}
            ref = {buttonEle}
          >
              {createButton.label}
          </Button>
        </Tooltip>
        :
        <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} className={`${createButton.className} flexLeftGap`}/>
      )}
      <Drawer
        title={<h2>{`${drawerOptions.title}`}</h2>}
        maskClosable={false}
        destroyOnClose={true}
        className="fixedFooterBtns "
        width={720}
        closable={false}
        onClose={() => setDrawerState(false)}
        open={drawerState}
        afterOpenChange={afterOpenChange}
        extra={
          <Button onClick={() => setDrawerState(false)} className="closeBlk">
            Close
          </Button>
        }
      >
        <Spin spinning={formLoader}>
          <CreateNewProjectForm visibility={visibility} />
        </Spin>
      </Drawer>
    </React.Fragment>
  );
};

export default OrgCreateNewProject;
