import { Button, Form, Input, InputNumber, Modal } from "antd";
import EditJurisdiction from "./EditJurisdiction";
import { useImporterToolStore } from "modules/organization/store/importerToolStore";

const EditRecord= () => {
  const {jurisdictionInfo, showEditRecordModal, setJurisdictionInfo, setJurisdictionData, setShowEditRecordModal} = useImporterToolStore();
  const [form] = Form.useForm();


  const handleFormSubmit = async (values: any) => {
    setJurisdictionData(
        jurisdictionInfo.rowIndex,
        {
          ...values,
          jurisdiction: values.jurisdiction_name,
          jurisdiction_types: values.place.type
        }
    );
    setShowEditRecordModal(false);
  };

  return (
    <Form
        form={form}
        autoComplete="off"
        className="drawerContent"
        onFinish={handleFormSubmit}
        initialValues={jurisdictionInfo}
    >
        <Modal
            className="confirmationModal"
            title={<h2>Edit {jurisdictionInfo.place.jurisdiction_value??'Record'}</h2>}
            centered
            open={showEditRecordModal}
            closable={true}
            width={720}
            onCancel={() => {
                setShowEditRecordModal(false);
                return setJurisdictionInfo({
                    rowIndex: 0,
                    place_id: null,
                    jurisdiction_id: null,
                    country: null,
                    jurisdiction_name: null,
                    place: {
                        type: "state",
                        jurisdiction_value: null,
                    },
                })
            }}
            footer={[
                <Button htmlType="button" disabled={false} key="cancel" onClick={() => {
                    setShowEditRecordModal(false);
                    return setJurisdictionInfo({
                        rowIndex: 0,
                        place_id: null,
                        jurisdiction_id: null,
                        country: null,
                        jurisdiction_name: null,
                        place: {
                            type: "state",
                            jurisdiction_value: null,
                        },
                    })
                }}>
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                    htmlType="submit"
                    disabled={false}
                    key="edit"
                >
                    Save
                </Button>,
            ]}
        >
            <div style={{zIndex: 200}}>        
                <EditJurisdiction/>
                <Form.Item
                    hidden={true}
                    name="jurisdiction_id"
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    hidden={true}
                    name="jurisdiction_name"
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    hidden={true}
                    name="country"
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    hidden={true}
                    name="place_id"
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        </Modal>
    </Form>
  );
};

export default EditRecord;
