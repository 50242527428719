import { message } from "antd";
import StageStepContainer from "./StageStepContainer";
import {
  useImporterToolStore,
} from "modules/organization/store/importerToolStore";
import Jurisdictions from "./tables/Jurisdictions";
import { ProjectImporterToolService } from "modules/organization/services/projectImporterTool.service";
import { useProjectDetailsStore } from "modules/organization/store";
import { onSaveJurisdictions } from "./tables/common";

const StageStepTwo = () => {
  const { projectDetails, clearProjectDetails, getProjectDetails } = useProjectDetailsStore();
  const { dataList, columnMapping, excelHeaderList, validationGlobalErrors, setDataSubmission } =
    useImporterToolStore();

  const onSave = () => {
    onSaveJurisdictions(
      dataList,
      columnMapping,
      excelHeaderList,
      validationGlobalErrors,
      projectDetails?.slug,
      true
    );
  }

  const onFinish = async () => {
    setDataSubmission(true);
    try {
      const finalJurisdictionData = {
        header: excelHeaderList,
        data: dataList
      }
      const payload = {
        project_slug: projectDetails?.slug,
        project_title: projectDetails?.title,
        jurisdiction_data: finalJurisdictionData,
        column_mapping: columnMapping
      }
      await new ProjectImporterToolService().updateImporterToolJurisdiction(projectDetails?.slug ?? "", payload);
      message.success({
        key: "updateJurisdictionData",
        content: "Jurisdiction data updated successfully!",
        duration: 2,
      });
      setDataSubmission(false);
      clearProjectDetails();
      getProjectDetails(projectDetails?.slug ?? "").catch((error) => console.log(error));
    } catch (error) {
      console.error(error);
      setDataSubmission(false);
      message.error({
        key: "updateJurisdictionData",
        content: "Some thing went wrong!",
        duration: 2,
      });
    }
  };

  return (
    <div className="importerContainer">
      <div className="publishRightContent">
        <StageStepContainer onFinish={onFinish} onSave={onSave}>
          <Jurisdictions componentName="Jurisdictions"/>
        </StageStepContainer>
      </div>
    </div>
  );
};

export default StageStepTwo;
