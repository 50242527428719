import { Fragment, useEffect, useMemo, useState } from "react";
import { BellFilled, CheckOutlined, CloseOutlined, ExclamationCircleFilled, InfoOutlined, LoadingOutlined, StopOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Drawer, Space, List, Badge, notification } from 'antd';
import { FirebaseService } from '../../../application/services/index';
import { useOrganizationRoutes } from "routes/organizationRoute";
import { useNavigate } from "react-router-dom";
import { useSiteStore } from "modules/organization/store/siteStore";

export const AppNotification = (props: any) => {
    const { organizationBaseRouteUrl } = useOrganizationRoutes();
    const { setPublishRow } = useSiteStore();
    const [api, contextHolder] = notification.useNotification();
    const [open, setOpen] = useState(false);
    const [notifications, setNotifications] = useState<any[]>([]);
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [lastVisible, setLastVisible] = useState<any>(null);
    const [hasMore, setHasMore] = useState(true); // Track if more notifications are available
    const [totalNotifications, setTotalNotifications] = useState(0);
    const navigate = useNavigate();

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const firebaseService = useMemo(() => new FirebaseService(), []);

    useEffect(() => {
        // Fetch total notification count
        firebaseService
            .fetchTotalNotificationCount(props.userDetails.org_id, props.userDetails.id)
            .then((count) => {
                if(totalNotifications > 0 && totalNotifications < count){
                    openPopUpNotice();
                }
                setTotalNotifications(count);
                checkHasMore(notifications.length, count); // Update `hasMore` state
            });
    }, [notifications]);

    const openPopUpNotice = () =>{
        api.open({
            message: 'New Notification',
            icon: <ExclamationCircleFilled style={{ color: "#fcb921" }} />,
            description: `You have unread notification(s)`,
            duration: 5,
        });
    }

    useEffect(() => {
        const fetchInitialNotifications = async () => {
            setLoading(true);
            firebaseService.fetchInitialNotifications(
                props.userDetails.org_id,
                props.userDetails.id,
                (snapshot) => {
                    const newNotifications: any[] = [];
                    snapshot.forEach((doc: any) => {
                        const notificationData = doc.data();
                        newNotifications.push({ id: doc.id, ...notificationData });
                    });
                    setNotifications(newNotifications);
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1]); // Set the last visible document
                    checkHasMore(notifications.length, totalNotifications); // Update `hasMore` state
                    setLoading(false);
                    setInitLoading(false);
                }
            );
        };

        fetchInitialNotifications();
    }, [props.userDetails, firebaseService]);

    // delete all notifications
    const onClearNotification = async () => {
        const payload = {
            "organization": props.userDetails.org_id,
            "user": props.userDetails.id
        }
        setLoading(true);
        await firebaseService.deleteAllNotification(payload);
        setOpen(false);
        setLoading(false);
    }

    // delete a single notification
    const onDeleteSingleNotification = async (notification: any) => {
        const payload = {
            "organization": props.userDetails.org_id,
            "user": props.userDetails.id,
            "notification_id": notification.id
        }
        setLoading(true);
        try {
            await firebaseService.deleteSingleNotification(payload);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            console.error(error);
            return false;
        }

    }

    // on click notification item
    const handleNotificationAction = async (notification: any) => {
        try {
            if(notification.type !== 'inProgress'){
                await onDeleteSingleNotification(notification);
            }
        } catch (error) {
            console.error(error);
        } finally {
            if(notification.action){
                const notificationIdParts = notification.id.split('_');
                if(notificationIdParts[0] === 'publish') {
                    setPublishRow(+notificationIdParts[2]);
                }
                navigate(`${organizationBaseRouteUrl}/${notification.action}`);
            }
            onClose();
        }
    };
    // load more notification when load more button is clicked
    const fetchMoreNotifications = (async () => {
        if (!lastVisible || !hasMore || loading) return;
        setLoading(true);
        firebaseService
            .fetchMoreNotifications(
                props.userDetails.org_id,
                props.userDetails.id,
                lastVisible // Start fetching after the last visible document
            )
            .then((result) => {
                if (result.notifications.length > 0) {
                    setNotifications((prevNotifications) => [
                        ...prevNotifications,
                        ...result.notifications,
                    ]);
                    setLastVisible(result.lastVisible);
                    checkHasMore(
                        notifications.length + result.notifications.length,
                        totalNotifications
                    );
                    setLoading(false);
                } else {
                    setHasMore(false);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });


    });

    // load more button
    const loadMore =
        !initLoading && !loading && hasMore ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button type="primary" className="loadMoreBtn" onClick={fetchMoreNotifications}>Load more</Button>
            </div>
        ) : null;

    // Function to check if more notifications are available
    const checkHasMore = (displayedCount: number, totalCount: number) => {
        setHasMore(displayedCount < totalCount);
    };

    // message body renderer
    const renderMessageBody = (messageBody:string) => {
        const markup = { __html: messageBody };
        return <div className="contMsg" dangerouslySetInnerHTML={markup} />;
    }
    // render message icon
    const renderMessageIcon = (messageType:string) => {
        switch(messageType) {
        case 'success':
            return <CheckOutlined/>;
        case 'error':
            return <StopOutlined />;
        case 'info':
            return <InfoOutlined />;
        case 'warn':
            return <WarningOutlined />;
        case 'inProgress':
            return <LoadingOutlined />;
        default:
            return <WarningOutlined />;
        }
    }

    return (

        <Fragment>
            {contextHolder}
            <Badge count={totalNotifications} overflowCount={25} size="small" className="appNotificationBadge">
                <BellFilled className="appNotificationIcon" onClick={showDrawer} style={{ fontSize: "24px", color: "#FFF" }} />
            </Badge>
            <Drawer
                title="Notifications"
                placement='right'
                width={500}
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        <Button type="primary" onClick={onClearNotification}>
                            Clear all
                        </Button>
                    </Space>
                }
            >
                
                <div className="notificationList">
                    <List
                        itemLayout="horizontal"
                        dataSource={notifications}
                        loading={loading}
                        loadMore={loadMore}
                        renderItem={(item, index) => (
                            
                            <li key={item.id}>
                                <span className={`relatedIcon ${item.type ? item.type : ''}` }>
                                    {renderMessageIcon(item.type)}
                                </span>
                                <div className="drMainCont" onClick={() => handleNotificationAction(item)}>
                                    <div className="contTitle">{item.title}</div>
                                    {renderMessageBody(item.body)}
                                    <div className="contDate">{`${item.created_on.toDate().toDateString()} ${item.created_on.toDate().toLocaleTimeString('en-US')}`}</div>
                                </div>
                                {
                                    item.type !== 'inProgress' && <span className="clearIcon"><CloseOutlined title="Delete this notification"  onClick={() => onDeleteSingleNotification(item)} /></span>
                                }
                            </li>
                        )}
                    />
                </div>
            </Drawer>
        </Fragment>

    );
}
