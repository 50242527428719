import { useJsApiLoader } from "@react-google-maps/api";
import { Form, message, Select, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { RecordsService } from "modules/organization/services/records.service";
import {
  useProjectDetailsStore,
  useProjectRecordsStore,
} from "modules/organization/store";
import { appConfig } from "config/config";
import { LoggerService } from "modules/shared/services";
import { IJurisdictionPayload } from "modules/organization/models/interface";
import GoogleMapViewer from "modules/organization/orgProject/orgProjectDetails/components/records/jurisdiction/GoogleMapViewer";
import { useImporterToolStore } from "modules/organization/store/importerToolStore";

function createUniqueArray(arr: any) {
  const uniqueArray: any = [];

  return arr?.filter((item: any) => {
    if (!uniqueArray.includes(item.value)) {
      uniqueArray.push(item.value);
      return true;
    }
    return false;
  });
}

async function fetchPlacesList(
  search: string,
  setData: Function,
  type: any,
  setSearchloading: Function,
  // customData: any
) {
  setSearchloading(true);
  // if (type === "custom") {
  //   try {
  //     const filteredData = customData?.map((place: any) => ({
  //       label: (
  //         <>
  //           <strong>(custom)</strong> {place.name}
  //         </>
  //       ),
  //       labelString: place.name,
  //       value: place.id,
  //       geofile: place.geojsonfilename,
  //       type: "custom",
  //     }));
  //     setData(filteredData ?? []);
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   return setSearchloading(false);
  // }
  const layer = type === "all" || type === "county" ? "" : `&type=${type}`;

  const res = await fetch(
    `https://api.geoapify.com/v1/geocode/autocomplete?text=${search}&apiKey=1b48259b810e48ddb151889f9ea58db0&limit=100${
      type === "state" || type === "city" || type === "county"
        ? "&filter=countrycode:us&bias=countrycode:us"
        : ""
    }${layer}`
  );

  const body: any = await res.json();

  let data = [];

  data =
    type !== "all"
      ? await body?.features?.filter(
          (each: any) => each.properties.result_type === type
        )
      : await body?.features;

  data = await data?.map((place: any) => ({
    label: (
      <>
        <strong>({place.properties.result_type})</strong>{" "}
        {Array.from(
          new Set([
            place.properties?.housenumber,
            place.properties?.name,
            place.properties?.street,
            place.properties?.city,
            place.properties?.county,
            place.properties?.state_district,
            place.properties?.state,
            place.properties?.postcode,
            place.properties?.country,
          ])
        )
          .filter((each) => each?.length > 1)
          .join(", ")}
      </>
    ),
    value: place.properties.place_id,
    type: place.properties.result_type,
  }));

  setData(createUniqueArray(data));
  return setSearchloading(false);
}

// const placeType: any = {
//   1: "country",
//   2: "state",
//   3: "city",
//   4: "county",
//   6: "custom",
//   5: "all",
// };

const placeType: any = {
  "country": "country",
  "states": "state",
  "state": "state",
  "cities": "city",
  "city": "city",
  "county": "county",
  "custom": "custom",
};

function getPlaceType(value: string) {
  return value ? (placeType[value] || "all") : "state";
}

const EditJurisdiction = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState([]);
  // const [customData, setCustomData] = useState([]);
  const [type, setType] = useState("state");
  const [geoData, setGeoData] = useState<any>(null);
  const [loadingMap, setLoadingMap] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const form = Form.useFormInstance();
  const [searchTimeOut, setSearchTimeOut] = useState<any>(null);
  const [dataFetchError, setDataFetchError] = useState<any>(null);
  const { projectDetails } = useProjectDetailsStore();
  const {
    setJurisdictionObj,
    setIsFetchingJurisdictionData,
  } = useProjectRecordsStore();
  const {jurisdictionInfo} = useImporterToolStore();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: appConfig.REACT_APP_MAP_API_KEY,
  });

  function getJurisdictionTypeId(type: string, country_code?: string) {
    if (type === "country") {
      return 1;
    }
    if (country_code !== "us") {
      if (type !== "custom") {
        return 5;
      }
      return 6;
    }
    if (type === "state") {
      return 2;
    } else if (type === "city") {
      return 3;
    } else if (type === "county") {
      return 4;
    }
    return 5;
  }

  function editAmendDetails(
    recordName: string,
    typeValue: string,
    cb: Function
  ) {
    if (jurisdictionInfo) {
      setType(typeValue)
      // if (jurisdictionInfo?.jurisdiction_id) {
      //   const selectValue = `${jurisdictionInfo?.jurisdiction_id}_null_${
      //     getPlaceType(jurisdictionInfo.jurisdiction_types)
      //   }_editAmend`;
      //   cb(selectValue);
      //   return;
      // }
      // Does not call autocomplete api if jurisdiction place_id already in DB
      if(jurisdictionInfo?.place_id){
        const selectValue = `${jurisdictionInfo?.place_id}_null_${
          getPlaceType(jurisdictionInfo.place.type)
        }`;
        cb(selectValue);
        return;
      }
      const query = [recordName]
        .filter((each: any) => each !== undefined && each !== null)
        .join(",");

      const layer =
        typeValue === "all" || typeValue === "county"
          ? ""
          : `&type=${typeValue}`;

      fetch(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${query}&apiKey=1b48259b810e48ddb151889f9ea58db0&limit=100${
          typeValue === "state" ||
          typeValue === "city" ||
          typeValue === "county"
            ? "&filter=countrycode:us&bias=countrycode:us"
            : ""
        }${layer}`
      )
        .then((r) => r.json())
        .then(async (d) => {
          const value = d.features[0]?.properties;
          if (!value?.place_id) {
            setDataFetchError({
              code: 500,
              message: "Sorry, Map cannot be loaded",
            });
            return new LoggerService().log({
              message:
                "MapViewer || No region found for given jurisdiction name by prediction",
              errors: {
                place_info: {
                  record_name: recordName,
                },
              },
            });
          }
          const selectValue = `${value?.place_id}_null_${
            getPlaceType(jurisdictionInfo.place.type)
          }`;
          cb(selectValue);
        })
        .catch((e) => {
          console.error(e);
          setDataFetchError({
            code: 500,
            message: "Sorry, Map cannot be loaded",
          });
          return setGeoData(null);
        });
    }
  }

  async function handleSelect(value: string) {
    setDataFetchError(null);
    const loadingJurisdictionDetails = "loadingJurisdictionDetails";

    message.loading({
      key: loadingJurisdictionDetails,
      content: "Fetching the jurisdiction details",
      duration: 0,
    });

    setLoadingMap(true);
    setIsFetchingJurisdictionData(true);
    // if (value.split("_")[2] === "custom") {
    //   fetch(value.split("_")[1])
    //     .then((r) => r.json())
    //     .then(async (d) => {
    //       setGeoData(d);
    //       setAmendRecordName(d?.properties?.name);
    //       setLoadingMap(false);
    //       if (
    //         !recordDetails ||
    //         [RecordMenuKeys.AMEND_RECORD, RecordMenuKeys.EDIT_RECORD].includes(
    //           activeMenuKey
    //         )
    //       ) {
    //         const payload = {
    //           jurisdiction_id: value.split("_")[0],
    //         };
    //         const resp = await new RecordsService().getAmendRecordsInfo(
    //           payload,
    //           projectDetails?.slug ?? ""
    //         );
    //         message.destroy(loadingJurisdictionDetails);
    //         form.setFieldValue(
    //           ["jurisdiction_id"],
    //           resp.data?.data?.jurisdiction_info?.jurisdiction_id
    //         );
    //         setJurisdictionObj(resp.data?.data?.jurisdiction_info);
    //       }
    //       return setIsFetchingJurisdictionData(false);
    //     })
    //     .catch((e) => {
    //       message.destroy(loadingJurisdictionDetails);
    //       console.error(e);
    //       setDataFetchError({
    //         code: 500,
    //         message: "Sorry, Map cannot be loaded",
    //       });
    //       return setGeoData(null);
    //     });
    //   return;
    // }
    let jurisdictionDetailsDB, payloadData;
    try {
      if (value.split("_")[3]) {
        payloadData = {
          jurisdiction_id: +value.split("_")[0],
        };
      } else {
        payloadData = {
          place_id: value.split("_")[0],
        };
      }
      jurisdictionDetailsDB = (
        await new RecordsService().getJurisdictionDetailsFromDB(
          payloadData,
          projectDetails?.slug ?? ""
        )
      ).data.data.jurisdiction_info;
      setGeoData(jurisdictionDetailsDB);
      setLoadingMap(false);
      form.setFieldValue(
        ["jurisdiction_id"],
        jurisdictionDetailsDB.properties.jurisdiction_id
      );
      form.setFieldValue(
        ["jurisdiction_name"],
        jurisdictionDetailsDB.properties.name
      );
      form.setFieldValue(
        ["place_id"],
        jurisdictionDetailsDB.properties.place_id
      );
      form.setFieldValue(
        ["country"],
        jurisdictionDetailsDB.properties.country
      );
      message.destroy(loadingJurisdictionDetails);
      setIsFetchingJurisdictionData(false);
    } catch (e) {
      message.destroy(loadingJurisdictionDetails);
      console.log("Map details not found in cache");
      setGeoData(null);
      setDataFetchError({
        code: 500,
        message: "Sorry, Map cannot be loaded",
      });
      setLoadingMap(false);
      setIsFetchingJurisdictionData(false);
    }
    if (jurisdictionDetailsDB) {
      return;
    }
    setDataFetchError(null);
    fetch(
      `https://api.geoapify.com/v2/place-details?id=${
        value.split("_")[0]
      }&lang=en&apiKey=1b48259b810e48ddb151889f9ea58db0`
    )
      .then((r) => r.json())
      .then(async (d) => {
        if (d?.features.length < 1) {
          setDataFetchError({
            code: 500,
            message: "Sorry, Map cannot be loaded",
          });
          setGeoData(null);
          message.destroy(loadingJurisdictionDetails);
          setIsFetchingJurisdictionData(false);
          setLoadingMap(false);
          return new LoggerService().log({
            message: "MapViewer || No details found for given place_id",
            errors: {
              place_info: {
                place_type: value.split("_")[2],
                place_id: value.split("_")[0],
              },
            },
          });
        }
        setGeoData(d?.features[0]);
        setLoadingMap(false);
        const jurisdictionName = !["country", "state"].includes(value.split("_")[2])
        ? Array.from(
            new Set([
              d?.features[0]?.properties?.housenumber,
              d?.features[0]?.properties?.street,
              d?.features[0]?.properties?.name_international?.en,
              d?.features[0]?.properties?.name,
              d?.features[0]?.properties?.city,
              value.split("_")[2] !== 'county' ? d?.features[0]?.properties?.county : "",
              d?.features[0]?.properties?.state_district,
              d?.features[0]?.properties?.state,
              d?.features[0]?.properties?.postcode,
              d?.features[0]?.properties?.country,
            ])
          )
            .filter((each) => each?.length > 1)
            .join(", ")
        : d?.features[0]?.properties?.name_international?.en ?? d?.features[0]?.properties?.name;
        const payload: IJurisdictionPayload = {
          place_id: value.split("_")[0],
          osm_id: d?.features[0]?.properties?.datasource?.raw?.osm_id,
          osm_type:
            d?.features[0]?.properties?.datasource?.raw?.osm_type?.toUpperCase(),
          jurisdiction_info: {
            name: jurisdictionName,
            type: value.split("_")[2],
            shape: d?.features[0]?.geometry?.type,
            lat: d?.features[0]?.properties?.lat,
            lon: d?.features[0]?.properties?.lon,
            state_code:
              d?.features[0]?.properties?.datasource?.raw?.state_code ||
              d?.features[0]?.properties?.datasource?.raw?.ref,
            country_code: (
              d?.features[0]?.properties?.datasource?.raw?.country_code ||
              d?.features[0]?.properties?.country_code || ''
            ).toUpperCase(),
            city: d?.features[0]?.properties?.city,
            state: d?.features[0]?.properties?.state,
            country: d?.features[0]?.properties?.country,
            county: d?.features[0]?.properties?.county,
            geojsonpropertiescenterlat: d?.features[0]?.properties?.lat,
            geojsonpropertiescenterlng: d?.features[0]?.properties?.lon,
            geojsonpropertiesbounds: JSON.stringify([]),
            geojsonfeatures: [
              {
                type: "Feature",
                geometry: {
                  type: `${d?.features[0]?.geometry?.type}`,
                  coordinates: d?.features[0]?.geometry?.coordinates,
                },
              },
            ],
            jurisdiction_type_id: getJurisdictionTypeId(
              value.split("_")[2],
              d?.features[0]?.properties?.country_code
            ),
          },
        };
        const resp = await new RecordsService().getAmendRecordsInfo(
          payload,
          projectDetails?.slug ?? ""
        );
        message.destroy(loadingJurisdictionDetails);
        form.setFieldValue(
          ["jurisdiction_id"],
          resp.data?.data?.jurisdiction_info?.jurisdiction_id
        );
        form.setFieldValue(
          ["jurisdiction_name"],
          jurisdictionName
        );
        form.setFieldValue(
          ["place_id"],
          value.split("_")[0]
        );
        form.setFieldValue(
          ["country"],
          d?.features[0]?.properties?.country
        );
        setJurisdictionObj(resp.data?.data?.jurisdiction_info);
        return setIsFetchingJurisdictionData(false);
      })
      .catch((e) => {
        message.destroy(loadingJurisdictionDetails);
        console.error(e);
        setGeoData(null);
        setDataFetchError({
          code: 500,
          message: "Sorry, Map cannot be loaded",
        });
        setLoadingMap(false);
        return setIsFetchingJurisdictionData(false);
      });
  }

  function handleChange(value: string) {
    if (value.length < 3 && type !== "custom") {
      return;
    }

    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        setSearchKeyword(value);
        fetchPlacesList(value, setData, type, setSearchLoading);
      }, 500)
    );
  }

  async function editRecords() {
    /**
     * If record details is null do nothing
     */
    if (!jurisdictionInfo.place_id) {
      return;
    }
    /**
     * If custom location geojson should not be null
     */
    // if (recordDetails.geofilename) {
    //   setType("custom");
    //   handleSelect(
    //     `${recordDetails.jurisdiction_id}_${recordDetails.geofilename}_${
    //       placeType(jurisdictionInfo.jurisdiction_types)
    //     }`
    //   );
    //   return;
    // }

    /**
     * This block is for user selected option
     */
    editAmendDetails(
      jurisdictionInfo.jurisdiction_name,
      getPlaceType(jurisdictionInfo.place.type),
      handleSelect
    );
  }
  /** DO NOT TOUCH THE COMMENTED CODE */
  // async function getCustomData() {
  //   const { data } = await new RecordsService().getCustomJurisdictions({
  //     search: "%%%%",
  //   });
  //   setCustomData(data.data.custom_jurisdictions);
  // }

  useEffect(() => {
    // getCustomData();
    editRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jurisdictionInfo]); //NO SONAR

  // useEffect(() => {
  //   if (type === "custom") {
  //     fetchPlacesList("", setData, type, setSearchLoading, customData);
  //   }
  // }, [type, customData]);

  useEffect(() => {
    return () => {
      if (searchTimeOut) {
        clearTimeout(searchTimeOut);
      }
    };
  }, [searchTimeOut]);

  return (
    <>
      <div className="coveredFldWrap jurisdictionFldWrap">
        <div className="coveredFld">
          <Form.Item
            label="Find Place"
            name={["place", "type"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              onChange={(value) => {
                setType(value);
              }}
              onSelect={() => {
                setData([]);
                form.setFieldValue(["place", "jurisdiction_value"], undefined);
                setSearchKeyword("");
              }}
              className="jurisdictionSelect"
              options={[
                {
                  label: "Countries",
                  value: "country",
                },
                {
                  label: "States(US)",
                  value: "state",
                },
                {
                  label: "Cities(US)",
                  value: "city",
                },
                {
                  label: "Counties(US)",
                  value: "county",
                },
                // {
                //   label: "Custom",
                //   value: "custom",
                // },
                {
                  label: "All Places",
                  value: "all",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name={["place", "jurisdiction_value"]}
            className="jurisdictionFld"
            rules={[
              {
                required: true,
                message: "Please enter jurisdiction name",
              },
            ]}
          >
            <Select
              showSearch
              allowClear={!searchLoading}
              onClear={() => {
                setSearchKeyword("");
                setJurisdictionObj(null);
                setGeoData(null);
                if (type !== "custom") {
                  setData([]);
                }
              }}
              placeholder="Search..."
              defaultActiveFirstOption={false}
              suffixIcon={!searchLoading && null}
              loading={searchLoading}
              filterOption={false}
              onSelect={handleSelect}
              notFoundContent={
                searchKeyword.length !== 0 && !searchLoading && "No data found"
              }
              onSearch={handleChange}
            >
              {data
                .filter((each: any) => {
                  if (type !== "custom") return true;
                  return (
                    searchKeyword === "" ||
                    each?.labelString
                      ?.toLowerCase()
                      .includes(searchKeyword?.toLowerCase())
                  );
                })
                .map((item: any) => {
                  return (
                    <Select.Option
                      key={`${item.value}`}
                      aria-label={item.label.props.children[2].toString()}
                      value={`${item.value}_${item.geofile}_${item.type}`}
                    >
                      <Tooltip
                        placement="top"
                        title={item.label.props.children[2].toString()}
                        trigger={['hover','focus']}
                      >
                        {item.label}
                      </Tooltip>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </div>
      </div>
      <div
        className={(!loadingMap && geoData) || dataFetchError ? "fldWrap" : ""}
      >
        {isLoaded && (
          <Spin spinning={loadingMap}>
            {!loadingMap && Boolean(geoData?.properties) && (
              <GoogleMapViewer geoData={geoData} loadingMap={loadingMap} />
            )}
            {loadingMap && <div style={{ height: "450px" }}></div>}
            {dataFetchError && (
              <div className="google-map">Sorry, Map cannot be loaded</div>
            )}
          </Spin>
        )}
      </div>
    </>
  );
};

export default EditJurisdiction;
